<template>
  <va-dropdown
    class="profile-dropdown"
    v-model="isShown"
    boundary-body
    position="bottom"
    :offset="[0, 13]"
  >
    <template #anchor>
      <span class="profile-dropdown__anchor">
        <slot/>
        <va-icon
          class="px-2"
          :name="isShown ? 'angle_up' :'angle_down'"
          :color="theme.primary"
        />
      </span>
    </template>
    <va-dropdown-content class="profile-dropdown__content">
      <va-list-item
        v-for="option in options"
        :key="option.name"
      >
        <router-link
          :to="{name: option.redirectTo}"
          class="profile-dropdown__item"
        >
          {{ $t(`user.${option.name}`) }}
        </router-link>          
      </va-list-item>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui'
import { LoginInfo } from "@/class/login";

export default {
  name: 'profile-section',
  data () {
    return {
      isShown: false,
      options: [],
      diretor: 0,
    }
  },
  props: {
    /*options: {
      type: Array,
      default: () => [
        {
          name: 'profile',
          redirectTo: 'perfil',
        },
        {
          name: 'logout',
          redirectTo: 'login',
        },
      ],
    },*/
    funcaoDoUsuario:{}
  },
  computed: {
    theme() { return useGlobalConfig().getGlobalConfig() },
  },
  methods:{
    async usuario(){
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);
      this.diretor = whoiam.data.diretor;
    }
  },
  async beforeMount(){
    await this.usuario();
    if(this.funcaoDoUsuario == 1 || this.funcaoDoUsuario == 2 || this.funcaoDoUsuario == 7){
      this.options = [
        {
            name: 'profile',
            redirectTo: 'perfil',
        },
        {
            name: 'logout',
            redirectTo: 'login',
        },
      ];
    }

    if(this.funcaoDoUsuario == 3 || this.funcaoDoUsuario == 4){

      this.options = [
        {
            name: 'profile',
            redirectTo: 'perfil-coord',
        },
        {
            name: 'logout',
            redirectTo: 'login',
        },
      ];
    }

    if(this.funcaoDoUsuario == 5){
      if(this.diretor == 1){
        this.options = [
          {
              name: 'profile',
              redirectTo: 'perfil-coord',
          },
          {
              name: 'logout',
              redirectTo: 'login',
          },
        ];
      }else{
      
        this.options = [
          {
              name: 'profile',
              redirectTo: 'perfil-prof',
          },
          {
              name: 'logout',
              redirectTo: 'login',
          },
        ];
      }
    }
  }
}
</script>

<style lang="scss">

.profile-dropdown {
  cursor: pointer;

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 8rem;
  }

  &__item {
    display: block;
    color: var(--va-gray);

    &:hover,
    &:active {
      color: var(--va-primary);
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
