<template>
  <va-sidebar
    :width="width"
    :minimized="minimized"
    :minimizedWidth="minimizedWidth"
  >
    <menu-minimized v-if="minimized" :items="items" />
    <menu-accordion v-else :items="items" />
  </va-sidebar>
  <div v-html="c"></div>
</template>

<script>
import { LoginInfo } from "@/class/login";
import { useGlobalConfig } from "vuestic-ui";
import MenuAccordion from "./menu/MenuAccordion.vue";
import MenuMinimized from "./menu/MenuMinimized.vue";
import NavigationRoutes from "./NavigationRoutesCoordenador";
import NavigationRoutes2 from "./NavigationRoutesCoordenador_profdiretor";

export default {
  name: "app-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: "16rem" },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      items: [],
      routes: [],
      c: "",
    };
  },
  computed: {
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized,
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors;
    },
  },
  methods: {
    montarRotas(permisoes, diretor) {
      this.routes = [];

      const educ = {
        name: "ambiente-professor",
        displayName: "Area de Trabalho",
        meta: {
          icon: "vuestic-iconset-statistics",
        },
        children: [],
      };

      const children = [];
      const novo = {
        name: "calendarioCoordenador",
        displayName: "Calendario Letivo",
      };
      children.push(novo);

      const painel = {
        name: "coordenacao-escola",
        displayName: "Painel de Gestão",
      };
      children.push(painel);

      // let relatorioCoord = {
      //   name: 'relatorios-coordenador',
      //   displayName: 'Relatórios',
      // };
      // children.push(relatorioCoord);
      for (const el of permisoes) {
        if (diretor == 1) {
          if (el.modulo == "Turmas - Professor") {
            const novo = {
              name: "turmas-Professor-cood",
              displayName: "Turmas do Professor",
            };
            children.push(novo);
          }
        }

        if (el.modulo == "Servidores") {
          const novo = {
            name: "coordenacao-servidores",
            displayName: "Servidores",
          };
          children.push(novo);
        }

        if (el.modulo == "Alunos") {
          const novo = {
            name: "coordenacao-listaalunos",
            displayName: "Alunos",
          };
          children.push(novo);
        }

        if (el.modulo == "Turmas") {
          const novo = {
            name: "coordenacao-turma-hab",
            displayName: "Turmas",
          };
          children.push(novo);
        }

        if (el.modulo == "Escolas") {
          const novo = {
            name: "escola-coordenacao",
            displayName: "Escolas",
          };
          children.push(novo);
        }

        if (el.modulo == "Segmento") {
          const novo = {
            name: "coordenacao-segmento",
            displayName: "Segmentos",
          };
          children.push(novo);
        }

        if (el.modulo == "Períodos") {
          const novo = {
            name: "coordenacao-serie",
            displayName: "Períodos",
          };
          children.push(novo);
        }

        if (el.modulo == "Disciplina") {
          const novo = {
            name: "coordenacao-disciplina",
            displayName: "Disciplinas",
          };
          children.push(novo);
        }
        if (el.modulo == "Notas - Gestão") {
          let novo = {
            name: "conteudo-aplicado-coordenador",
            displayName: "Conteúdo Aplicado",
          };
          children.push(novo);

          novo = {
            name: "dashboard-relatorio",
            displayName: "Relatorio",
          };
          children.push(novo);
        }

        if (el.modulo == "Transferencia") {
          const novo = {
            name: "coordenador/transferencia",
            displayName: "Solicitações de Transferência",
          };
          children.push(novo);
        }

        if (el.modulo == "Notas - Gestão") {
          const novo = {
            name: "educ-infantil-coord",
            displayName: "Educação Infantil",
          };
          children.push(novo);
        }
      }

      educ.children = children;
      this.routes.push(educ);
    },
  },
  async beforeMount() {
    const colorBar = sessionStorage.getItem("colorBar");
    const colorBar_text = sessionStorage.getItem("colorBar_text");
    const colorDiaLetivo = sessionStorage.getItem("colorDiaLetivo");
    if (colorBar) {
      this.c =
        "<style>.va-sidebar{background-color: " +
        colorBar +
        " !important; color: " +
        colorBar_text +
        " !important } .app-layout__sidebar-wrapper{background-color: " +
        colorBar +
        " !important;} .color_letivo {background-color: " +
        colorDiaLetivo +
        " !important} </style>";
    }

    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    if (whoiam.data.diretor == 0) {
      this.montarRotas(whoiam.data.permissao, whoiam.data.diretor);
      this.items = this.routes;
      //this.items = NavigationRoutes.routes;
    } else {
      this.montarRotas(whoiam.data.permissao, whoiam.data.diretor);
      this.items = this.routes;
      //this.items = NavigationRoutes2.routes;
    }
  },
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
