import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import DefaultLayout from "@/layout/default.vue";
import AuthLayout from "@/layout/auth-layout.vue";
import AppLayout from "@/layout/app-layout.vue";
import AmbienteProfessorLayout from "@/layout/ambiente-professor-layout.vue";
import AmbienteCoordenadorLayout from "@/layout/ambiente-coordenador-layout.vue";

import AmbienteEscolarLayout from "@/layout/ambiente-escolar-layout.vue";
import AmbienteEducacional from "@/layout/ambiente-educacional-layout.vue";
import Page404Layout from "@/layout/page-404-layout.vue";

import RouteViewComponent from "./route-view.vue";
import UIRoute from "@/pages/admin/ui/route";
import Util from "@/utils/rota";
import store from "@/store/store_token_info";
const util = new Util();

const token = sessionStorage.getItem("token");

const verificarToken = async function () {
  const data = await util.WhoIam(token);
  if (data.status == 1) {
    return data;
  } else {
    return 0;
  }
};

const ambienteADM = async function (verificar: any) {
  if (verificar.status == 1) {
    if (
      verificar.funcao == 1 ||
      verificar.funcao == 2 ||
      verificar.funcao == 7
    ) {
      store.permissao = verificar.permissao;
      return 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

const ambienteProf = async function (verificar: any) {
  if (verificar.status == 1) {
    if (verificar.funcao == 5) {
      store.permissao = verificar.permissao;
      return 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

const ambienteCoord = async function (verificar: any) {
  if (verificar.status == 1) {
    if (verificar.funcao == 3 || verificar.funcao == 4) {
      store.permissao = verificar.permissao;
      return 1;
    } else {
      if (verificar.diretor == 1) {
        store.permissao = verificar.permissao;
        return 1;
      } else {
        return 0;
      }
    }
  } else {
    return 0;
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "login" },
  },

  // //Ambiente relatorio

  // {
  //   name: "relatorios",
  //   path: "/relatorios",
  //   props: true,
  //   component: () => import("@/layout/relatorio-layout.vue"),
  //   redirect: "/dashboard",
  //   children: [
  //     {
  //       name: "dashboard-relatorio",
  //       path: "dashboard",
  //       props: true,
  //       component: () => import("@/pages/admin/pages/relatorios/dashboard.vue"),
  //       beforeEnter: async (to, from, next) => {
  //         const token = sessionStorage.getItem("token");
  //         if (token) {
  //           return next();
  //         } else {
  //           next("/auth/login");
  //           return next(false);
  //         }
  //       },
  //     },
  //     {
  //       name: "livro-matricula",
  //       path: "livro-matricula",
  //       props: true,
  //       component: () =>
  //         import("@/pages/admin/pages/relatorios/livromatricula.vue"),
  //     },
  //   ],
  // },

  //Ambiente relatorio

  {
    name: 'relatorios',
    path: '/relatorios',
    props:true,
    component: () => import('@/layout/relatorio-layout.vue'),
    redirect: '/dashboard',
    children: [
      {
        name: 'dashboard-relatorio',
        path: 'dashboard',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/dashboard.vue'),
        beforeEnter: async (to, from, next) => {
          const token = sessionStorage.getItem("token");
          if(token){
            return next();
          }else{
            next('/auth/login');
            return next(false);
          }

        }
      },
      {
        name: 'livro-matricula',
        path: 'livro-matricula',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/livromatricula.vue'),
      },
      {
        name: 'ficha-individual',
        path: 'ficha-individual',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/fichaindividual.vue'),
      },
      {
        name: 'atas',
        path: 'atas',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/atas.vue'),
      },
      {
        name: 'ataAlunos',
        path: '/ataAlunos',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/atasAlunos.vue'),
      },
      {
        name: 'boletim-notas',
        path: 'boletim-notas',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/boletim-notas.vue'),
      },
      {
        name: 'conteudos-aplicados',
        path: 'conteudos-aplicados',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/conteudos-aplicados.vue'),
      },
      {
        name: 'aluno-transporte',
        path: 'aluno-transporte',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/alunos-transporte.vue'),
      },
      {
        name: 'saude-social',
        path: 'saude-social',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/saudesocial.vue'),
      },
      {
        name: 'aluno-especial',
        path: 'aluno-especial',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/alunos-com-deficiencia.vue'),
      },
      {
        name: 'configuracao-relatorio',
        path: 'configuracao-relatorio',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/configuracao.vue'),
      },
    ],
  },

  // //Ambiente relatorio

  // {
  //   name: 'relatorios',
  //   path: '/relatorios',
  //   props:true,
  //   component: () => import('@/layout/relatorio-layout.vue'),
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       name: 'dashboard-relatorio',
  //       path: 'dashboard',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/dashboard.vue'),
  //       beforeEnter: async (to, from, next) => {
  //         const token = sessionStorage.getItem("token");
  //         if(token){
  //           return next();
  //         }else{
  //           next('/auth/login');
  //           return next(false);
  //         }

  //       }
  //     },
  //     {
  //       name: 'livro-matricula',
  //       path: 'livro-matricula',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/livromatricula.vue'),
  //     },
  //     {
  //       name: 'ficha-individual',
  //       path: 'ficha-individual',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/fichaindividual.vue'),
  //     },
  //     {
  //       name: 'atas',
  //       path: 'atas',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/atas.vue'),
  //     },
  //     {
  //       name: 'ataAlunos',
  //       path: '/ataAlunos',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/atasAlunos.vue'),
  //     },
  //     {
  //       name: 'boletim-notas',
  //       path: 'boletim-notas',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/boletim-notas.vue'),
  //     },
  //     {
  //       name: 'conteudos-aplicados',
  //       path: 'conteudos-aplicados',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/conteudos-aplicados.vue'),
  //     },
  //     {
  //       name: 'aluno-transporte',
  //       path: 'aluno-transporte',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/alunos-transporte.vue'),
  //     },
  //     {
  //       name: 'saude-social',
  //       path: 'saude-social',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/saudesocial.vue'),
  //     },
  //     {
  //       name: 'aluno-especial',
  //       path: 'aluno-especial',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/alunos-com-deficiencia.vue'),
  //     },
  //     {
  //       name: 'configuracao-relatorio',
  //       path: 'configuracao-relatorio',
  //       props:true,
  //       component: () => import('@/pages/admin/pages/relatorios/configuracao.vue'),
  //     },
  //   ],
  // },

  // Ambiente do Professor

  {
    name: "ambiente-professor",
    path: "/ambiente-professor",
    component: AmbienteProfessorLayout,
    beforeEnter: async (to, from, next) => {
      try {
        const verificar = await verificarToken();
        const result = await ambienteProf(verificar);
        if (result) {
          return next();
        } else {
          next("/auth/login");
          return next(false);
        }
      } catch (e) {
        next("/auth/login");
      }
    },
    redirect: "/turmas-professor",
    children: [
      {
        name: "painel-professor",
        path: "painel-professor",
        props: true,
        component: () =>
          import("@/pages/admin/pages/painel/professor/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      /*{
        name: 'relatorios-professor',
        path: 'relatorios-professor',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/professor/relatorios.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },*/

      {
        name: "turmas-Professor",
        path: "turmas-professor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/professor/turmas_do_professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 16) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "calendarioProfessor",
        path: "calendario-professor",
        props: true,
        component: () =>
          import("@/pages/admin/pages/calendario/professor/calendario.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 1) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "perfil-prof",
        props: true,
        path: "perfil-prof",
        component: () =>
          import("@/pages/admin/pages/servidores/professor/perfil.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "presencaProfessor",
        path: "presenca",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/presenca/professor/presenca-visual-professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 11) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
        children: [
          {
            name: "fevereiro",
            path: "fevereiro",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/fevereiro.vue"
              ),
          },
          {
            name: "presencaProfessormarco",
            path: "marco",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/marco.vue"
              ),
          },
          {
            name: "presencaProfessorabril",
            path: "abril",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/abril.vue"
              ),
          },
          {
            name: "presencaProfessormaio",
            path: "maio",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/maio.vue"
              ),
          },
          {
            name: "presencaProfessorjunho",
            path: "junho",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/junho.vue"
              ),
          },
          {
            name: "presencaProfessorjulho",
            path: "julho",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/julho.vue"
              ),
          },
          {
            name: "presencaProfessoragosto",
            path: "agosto",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/agosto.vue"
              ),
          },
          {
            name: "presencaProfessorsetembro",
            path: "setembro",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/setembro.vue"
              ),
          },
          {
            name: "presencaProfessoroutubro",
            path: "outubro",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/outubro.vue"
              ),
          },
          {
            name: "presencaProfessornovembro",
            path: "novembro",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/novembro.vue"
              ),
          },
          {
            name: "presencaProfessordezembro",
            path: "dezembro",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/professor/calendarios/dezembro.vue"
              ),
          },
        ],
      },

      {
        name: "notasProfessor",
        path: "notas",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/notas/professor/notas-visual-professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 13) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "planejamentoProfessor",
        path: "planejamento",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/planejamento_pedagogico/professor/planejamento_prof.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 15) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "crecheFormulario",
        path: "creche-formulario",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/perguntas/professor/formularioCreche.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "respoderPerguntas",
        path: "pergunta-formulario-responder",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/perguntas/professor/responderAsPerguntas.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteProf(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
    ],
  },

  // Ambiente do Coordenador

  {
    name: "ambiente-coordenador",
    path: "/ambiente-coordenacao",
    component: AmbienteCoordenadorLayout,
    beforeEnter: async (to, from, next) => {
      try {
        const verificar = await verificarToken();
        const result = await ambienteCoord(verificar);
        if (result) {
          return next();
        } else {
          next("/auth/login");
          return next(false);
        }
      } catch (e) {
        next("/auth/login");
      }
    },
    redirect: "/turmas-coordenador",
    children: [
      {
        name: "conteudo-aplicado-coordenador",
        path: "conteudo-aplicado-coordenador",
        props: true,
        component: () =>
          import("@/pages/admin/pages/conteudo-aplicado/coordenador/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "fotos-coord",
        path: "fotos-coord",
        props: true,
        component: () => import("@/pages/admin/pages/alunos/fotos/fotos.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "educ-infantil-coord",
        path: "educ-infantil-coord",
        props: true,
        component: () => import("@/pages/admin/pages/perguntas/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "turmas-coordenador",
        path: "turmas-coordenador",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/coordenador/turmas_do_coordenador.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "perfil-coord",
        props: true,
        path: "perfil-coord",
        component: () =>
          import("@/pages/admin/pages/servidores/coordenador/perfil.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "turma-coordenador-single",
        path: "turma/:id",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/coordenador/turmas_do_coordenador_single.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "calendarioCoordenador",
        path: "calendario-coordenador",
        props: true,
        component: () =>
          import("@/pages/admin/pages/calendario/coordenador/calendario.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      /*{
        name: 'relatorios-coordenador',
        path: 'relatorios-coordenador',
        props:true,
        component: () => import('@/pages/admin/pages/relatorios/coordenador/relatorios.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },*/
      {
        name: "coordenacao-escola",
        path: "coordenacao-escolas",
        props: true,
        component: () =>
          import("@/pages/admin/pages/painel/coordenador/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-escola-visu",
        path: "coordenacao-escolas-visu",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escola/coordenador/escolaEditar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-turma-config",
        path: "coordenacao-turma-config",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/turma_configurar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-turma-cadastrar",
        path: "coordenacao-turma-cadastrar",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/turmaCadastrar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "turmas-Professor-cood",
        path: "turmas-professor-cood",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/coordenador/turmas_do_professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "presencaDiretor",
        path: "presencaDiretor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/presenca/coordenador/presenca-visual-professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 11) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "notasDiretor",
        path: "notasDiretor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/notas/coordenador/notas-visual-professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 13) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "crecheFormularioDiretor",
        path: "creche-formularioDiretor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/perguntas/coordenador/formularioCreche.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "planejamentoDiretor",
        path: "planejamentoDiretor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/planejamento_pedagogico/coordenador/planejamento_prof.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 15) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "turma-notas-alunos",
        path: "turma-notas-alunos",
        props: true,
        component: () =>
          import("@/pages/admin/pages/notas/coordenador/notas-turmas.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "turma-presencas-alunos",
        path: "turma-presencas-alunos",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/presenca/coordenador/presenca-turmas.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-turma-edit",
        path: "coordenacao-turma-edit",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/turmaEditar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-turma-hab",
        path: "coordenacao-turmas-hab",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/turmas_escola.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-servidores",
        path: "coordenacao-servidores",
        props: true,
        component: () =>
          import("@/pages/admin/pages/servidores/coordenador/servidores.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenadoresadd-coordenador",
        path: "coordenadores-add-coordenador",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/coordenador/coordenador/coordenadoresAdd.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenadoresedit-coordenador",
        path: "coordenacao-editar-coordenador",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/coordenador/coordenador/coordenadoresEditar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "diretoradd-coordenador",
        path: "diretor-add-coordenador",
        props: true,
        component: () =>
          import("@/pages/admin/pages/diretor/coordenador/diretorAdd.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "diretoredit-coordenador",
        path: "diretor-edit-coordenador",
        props: true,
        component: () =>
          import("@/pages/admin/pages/diretor/coordenador/diretorEditar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenador-servidor-basic-info-cadastrar",
        path: "coordenador-servidor-basic-info-cadastrar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/cadastrar/servidor-basic-info.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenador-login-pessoal-servidor",
        path: "coordenador-login-pessoal-servidor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/cadastrar/login-dados-pessoais.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-endereco-contato-servidor",
        path: "coordenador-endereco-contato-servidor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/cadastrar/endereco-contato.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-confirmar-envio-dados-servidor",
        path: "coordenador-confirmar-envio-dados-servidor",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/cadastrar/confirmarEnvio.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-servidor-basic-info-editar",
        path: "coordenador-servidor-basic-info-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/editar/servidor-basic-info.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-login-dados-pessoais-servidor-editar",
        path: "coordenador-login-dados-pessoais-servidor-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/editar/login-dados-pessoais.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-endereco-contato-servidor-editar",
        path: "coordenador-endereco-contato-servidor-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/editar/endereco-contato.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenador-confirmar-envio-dados-servidor-editar",
        path: "coordenador-confirmar-envio-dados-servidor-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/servidores/coordenador/steps/editar/confirmarEnvio.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 7) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "professorCoordenacaoSingle",
        path: "professorCoordenacaoSingle",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/professores/coordenador/professores-single.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-professores",
        path: "coordenacao-professores",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/professores/coordenador/professores-all.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-professores-add",
        path: "coordenacao-professores-add",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/professores/coordenador/professorCadastrar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-professores-single",
        path: "coordenacao-professores-single",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/professores/coordenador/professores-single.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "escola-coordenacao",
        path: "escola-coordenacao",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escola/coordenador/escolas.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "escola-coordenacao-cadastrar",
        path: "escola-coordenacao-cadastrar",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escola/coordenador/escolasCadastrar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "escola-coordenacao-editar",
        path: "escola-coordenacao-editar",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escola/coordenador/escolaEditar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "escola-coordenacao-informacao",
        path: "escola-coordenacao-informacao",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escola/coordenador/escolaInformacao.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-listaalunos",
        path: "coordenacao-lista-alunos",
        props: true,
        component: () =>
          import("@/pages/admin/pages/alunos/coordenador/alunos.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-alunos-hist",
        path: "coordenacao-alunos-hist",
        props: true,
        component: () =>
          import("@/pages/admin/pages/alunos/coordenador/aluno-historico.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-alunos-arq",
        path: "coordenacao-alunos-arq",
        props: true,
        component: () =>
          import("@/pages/admin/pages/alunos/coordenador/alunoArquivo.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-coord-all",
        path: "coordenacao-coord-all",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/coordenador/coordenador/coordenadores-all.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenacao-coord-add",
        path: "coordenacao-coord-add",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/coordenador/coordenador/coordenadoresAdd.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "coordenacao-coord-edit",
        path: "coordenacao-coord-edit",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/coordenador/coordenador/coordenadoresEditar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      //rotas do coordenador sobre o aluno cadastrar
      {
        name: "aluno-basic-info-cadastrar-do-aluno-coordenacao",
        path: "aluno-basic-info-cadastrar-do-aluno-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/cadastro/aluno-basic-info.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "login-pessoal-do-aluno-coordenacao",
        path: "login-pessoal-do-aluno-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/cadastro/login-dados-pessoais.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "endereco-contato-do-aluno-coordenacao",
        path: "endereco-contato-do-aluno-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/cadastro/endereco-contato.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "confirmar-envio-dados-coordenacao",
        path: "confirmar-envio-dados-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/cadastro/confirmarEnvio.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      //Editar aluno parte coordenador
      {
        name: "aluno-basic-info-cadastrar-do-aluno-coordenacao-editar",
        path: "aluno-basic-info-cadastrar-do-aluno-coordenacao-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/editar/aluno-basic-info.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "login-pessoal-do-aluno-coordenacao-editar",
        path: "login-pessoal-do-aluno-coordenacao-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/editar/login-dados-pessoais.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "endereco-contato-do-aluno-coordenacao-editar",
        path: "endereco-contato-do-aluno-coordenacao-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/editar/endereco-contato.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "confirmar-envio-dados-coordenacao-editar",
        path: "confirmar-envio-dados-coordenacao-editar",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/alunos/coordenador/steps/editar/confirmarEnvio.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-segmento",
        path: "coordenacao-segmento",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/segmento-ensino/coordenador/segmento.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-segmento-add",
        path: "coordenacao-segmento-add",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/segmento-ensino/coordenador/segmentoCadastro.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-segmento-edit",
        path: "coordenacao-segmento-edit",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/segmento-ensino/coordenador/segmentoEditar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-disciplina",
        path: "coordenacao-disciplina",
        props: true,
        component: () =>
          import("@/pages/admin/pages/disciplinas/coordenador/disciplina.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-disciplina-add",
        path: "coordenacao-disciplina-add",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/disciplinas/coordenador/disciplinaCadastro.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-disciplina-edit",
        path: "coordenacao-disciplina-edit",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/disciplinas/coordenador/disciplinaEditar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-serie",
        path: "coordenacao-serie",
        props: true,
        component: () =>
          import("@/pages/admin/pages/series/coordenador/serie.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-serie-add",
        path: "coordenacao-serie-add",
        props: true,
        component: () =>
          import("@/pages/admin/pages/series/coordenador/serieCadastro.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenacao-serie-edit",
        path: "coordenacao-serie-edit",
        props: true,
        component: () =>
          import("@/pages/admin/pages/series/coordenador/serieEditar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "turma-add-disciplina-coordenacao",
        path: "turma-add-disciplina-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/coordenador/turma_add_disciplina.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "turma-add-aluno-coordenacao",
        path: "turma-add-aluno-coordenacao",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/turma_add_aluno.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "turma-add-professor-coordenacao",
        path: "turma-add-professor-coordenacao",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/turmas/coordenador/turma_add_professor.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "visualizarturma-coordenacao",
        path: "visualizar-turma-coordenacao",
        props: true,
        component: () =>
          import("@/pages/admin/pages/turmas/coordenador/visualizar_turma.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "escolaridade-coord",
        path: "escolaridade-coord",
        props: true,
        component: () =>
          import("@/pages/admin/pages/escolaridade/escolaridade.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();

            const result = await ambienteCoord(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "coordenador/transferencia",
        path: "/coordenador/transferencia",
        props: true,
        component: () =>
          import(
            "@/pages/admin/pages/transferencia/coordenador/transferencia.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteCoord(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 18) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 0;
            }
            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ],
  },

  // Ambiente do Gestor
  {
    name: "admin",
    path: "/admin",
    component: AppLayout,
    //redirect: 'inicio',
    children: [
      {
        name: "painel",
        path: "painel-adm",
        component: () => import("@/pages/admin/pages/painel/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "educ-infantil-adm",
        path: "educ-infantil-adm",
        props: true,
        component: () => import("@/pages/admin/pages/perguntas/index.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "fotos",
        path: "fotos",
        props: true,
        component: () => import("@/pages/admin/pages/alunos/fotos/fotos.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "permissao",
        path: "permissao",
        component: () =>
          import("@/pages/admin/pages/permissao/gestor/permissao.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "permissao-cadastrar",
        path: "permissao-cadastrar",
        component: () =>
          import(
            "@/pages/admin/pages/permissao/gestor/permissao-cadastrar.vue"
          ),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },
      {
        name: "permissao-editar",
        props: true,
        path: "permissao-editar",
        component: () =>
          import("@/pages/admin/pages/permissao/gestor/permissao-editar.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "perfil",
        props: true,
        path: "perfil-usuario",
        component: () =>
          import("@/pages/admin/pages/servidores/gestor/perfil.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "calendario",
        path: "calendario-letivo",
        component: () =>
          import("@/pages/admin/pages/calendario/gestor/calendario.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 1) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "config-calendario",
        path: "configuracao",
        props: true,
        component: () =>
          import("@/pages/admin/pages/calendario/gestor/configuracao.vue"),
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            let permissao = 0;

            if (verificar.funcao != 1) {
              const permissoes = verificar.permissao;
              for (const el of permissoes) {
                if (el.permissao_modulo_id == 1) {
                  permissao = 1;
                }
              }
            } else {
              permissao = 1;
            }

            if (result) {
              if (permissao) {
                return next();
              } else {
                next("/auth/login");
                return next(false);
              }
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },
      },

      {
        name: "configuracao",
        path: "configuracao",
        component: RouteViewComponent,
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },

        children: [
          {
            name: "automatizar",
            path: "automatizar",
            component: () =>
              import("@/pages/admin/pages/configuracao/gestor/automatizar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "automatizaradd",
            path: "automatizaradd",
            component: () =>
              import(
                "@/pages/admin/pages/configuracao/gestor/automatizar-add.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            props: true,
            name: "automatizareditar",
            path: "automatizareditar",
            component: () =>
              import(
                "@/pages/admin/pages/configuracao/gestor/automatizar-editar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "logo",
            path: "logo",
            component: () =>
              import("@/pages/admin/pages/configuracao/gestor/config-logo.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "configuracao-sistema",
            path: "configuracao-sistema",
            component: () =>
              import(
                "@/pages/admin/pages/configuracao/gestor/configuracao.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
        ],
      },

      {
        name: "educacao",
        path: "educacao",
        component: RouteViewComponent,
        beforeEnter: async (to, from, next) => {
          try {
            const verificar = await verificarToken();
            const result = await ambienteADM(verificar);
            if (result) {
              return next();
            } else {
              next("/auth/login");
              return next(false);
            }
          } catch (e) {
            next("/auth/login");
          }
        },

        children: [
          {
            name: "professortipos",
            path: "professortipos",
            component: () =>import("@/pages/admin/pages/professorTipo/index.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                return next();
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "escolas",
            path: "escolas",
            component: () =>
              import("@/pages/admin/pages/escola/gestor/escolas.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);

                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 2) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "escolasCadastro",
            path: "escola-cadastro",
            component: () =>
              import("@/pages/admin/pages/escola/gestor/escolasCadastrar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;
                console.log("AW", permissao);
                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 2) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }
                permissao = 1;
                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "escolasEditar",
            path: "escola-editar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/escola/gestor/escolaEditar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 2) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "escolasInfo",
            path: "escolasInfo",
            props: true,
            component: () =>
              import("@/pages/admin/pages/escola/gestor/escolaInformacao.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 2) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "segmentos",
            path: "segmentos",
            component: () =>
              import("@/pages/admin/pages/segmento-ensino/gestor/segmento.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 3) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "segmentosCadastro",
            path: "segmentos-cadastro",
            component: () =>
              import(
                "@/pages/admin/pages/segmento-ensino/gestor/segmentoCadastro.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 3) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "segmentosEditar",
            path: "segmentos-Editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/segmento-ensino/gestor/segmentoEditar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 3) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "series",
            path: "series",
            component: () =>
              import("@/pages/admin/pages/series/gestor/serie.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 4) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "serieCadastro",
            path: "serie-cadastro",
            component: () =>
              import("@/pages/admin/pages/series/gestor/serieCadastro.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 4) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "serieEditar",
            path: "serie-Editar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/series/gestor/serieEditar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 4) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "disciplinas",
            path: "disciplina",
            component: () =>
              import("@/pages/admin/pages/disciplinas/gestor/disciplina.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 6) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "disciplinaCadastro",
            path: "disciplina-cadastro",
            component: () =>
              import(
                "@/pages/admin/pages/disciplinas/gestor/disciplinaCadastro.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 6) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "disciplinaEditar",
            path: "disciplina-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/disciplinas/gestor/disciplinaEditar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 6) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "servidores",
            path: "servidores",
            props: true,
            component: () =>
              import("@/pages/admin/pages/servidores/gestor/servidores.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "addTurnoProfessor",
            path: "add-turno-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/add_turno_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "verTurnoProfessor",
            path: "ver-turno-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/ver_turno_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "addDisciplinaProfessor",
            path: "add-disciplina-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/add_disciplina_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "editarDisciplinaProfessor",
            path: "editar-disciplina-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/editar_disciplina_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "deletarDisciplinaProfessor",
            path: "deletar-disciplina-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/deletar_disciplina_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "professoresall",
            path: "professores-all",
            component: () =>
              import(
                "@/pages/admin/pages/professores/gestor/professores-all.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "professor",
            path: "professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/professores/gestor/professores-single.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "professorCadastro",
            path: "cadastro-professor",
            component: () =>
              import(
                "@/pages/admin/pages/professores/gestor/professorCadastrar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "EditarProfessor",
            path: "editar-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/professores/gestor/professorEditar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "coordenadoresall",
            path: "coordenadores-all",
            component: () =>
              import(
                "@/pages/admin/pages/coordenador/gestor/coordenadores-all.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "coordenadoresadd",
            path: "coordenadores-add",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/coordenador/gestor/coordenadoresAdd.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "coordenadoresedit",
            path: "coordenadores-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/coordenador/gestor/coordenadoresEditar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "diretorall",
            path: "diretor-all",
            component: () =>
              import("@/pages/admin/pages/diretor/gestor/diretor-all.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "diretoradd",
            path: "diretor-add",
            props: true,
            component: () =>
              import("@/pages/admin/pages/diretor/gestor/diretorAdd.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "diretoreditar",
            path: "diretor-editar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/diretor/gestor/diretorEditar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "alunosAll",
            path: "alunos",
            props: true,
            component: () =>
              import("@/pages/admin/pages/alunos/gestor/alunos.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "alunoArquivo",
            path: "aluno-add-arquivo",
            props: true,
            component: () =>
              import("@/pages/admin/pages/alunos/gestor/alunoArquivo.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "alunoaddturma",
            path: "aluno-add-Turma",
            props: true,
            component: () =>
              import("@/pages/admin/pages/alunos/gestor/aluno-add-turma.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "alunohistorico",
            path: "aluno-historico",
            props: true,
            component: () =>
              import("@/pages/admin/pages/alunos/gestor/aluno-historico.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "aluno-basic-info-cadastrar",
            path: "aluno-basic-info-cadastrar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos//gestor/steps/cadastro/aluno-basic-info.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "login-pessoal",
            path: "login-pessoal",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/cadastro/login-dados-pessoais.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "endereco-contato",
            path: "endereco-contato",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/cadastro/endereco-contato.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "confirmar-envio-dados",
            path: "confirmar-envio-dados",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/cadastro/confirmarEnvio.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "aluno-basic-info-editar",
            path: "aluno-basic-info-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos//gestor/steps/editar/aluno-basic-info.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "login-pessoal-editar",
            path: "login-pessoal-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/editar/login-dados-pessoais.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "endereco-contato-editar",
            path: "endereco-contato-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/editar/endereco-contato.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "confirmar-envio-dados-editar",
            path: "confirmar-envio-dados-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/alunos/gestor/steps/editar/confirmarEnvio.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 8) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "servidor-basic-info-cadastrar",
            path: "servidor-basic-info-cadastrar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/cadastrar/servidor-basic-info.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "login-pessoal-servidor",
            path: "login-pessoal-servidor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/cadastrar/login-dados-pessoais.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "endereco-contato-servidor",
            path: "endereco-contato-servidor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/cadastrar/endereco-contato.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "confirmar-envio-dados-servidor",
            path: "confirmar-envio-dados-servidor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/cadastrar/confirmarEnvio.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "servidor-basic-info-editar",
            path: "servidor-basic-info-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/editar/servidor-basic-info.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "login-dados-pessoais-servidor-editar",
            path: "login-dados-pessoais-servidor-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/editar/login-dados-pessoais.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "endereco-contato-servidor-editar",
            path: "endereco-contato-servidor-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/editar/endereco-contato.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "confirmar-envio-dados-servidor-editar",
            path: "confirmar-envio-dados-servidor-editar",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/servidores/gestor/steps/editar/confirmarEnvio.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 7) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "turma_configurar",
            path: "turma-configurar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/turma_configurar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "turmas",
            path: "turmas",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/turmas_escola.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "turmasCadastrar",
            path: "turmas-cadastrar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/turmaCadastrar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "turmasEditar",
            path: "turmas-editar",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/turmaEditar.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "turma-add-disciplina",
            path: "turma-add-disciplina",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/turmas/gestor/turma_add_disciplina.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "planejamentoPedaAll",
            path: "visualizar-planejamento-pedagogico",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/index.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "planejamentoPedaProf",
            path: "visualizar-planejamento-professores",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/professores.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "planejamentoVisu",
            path: "visualizar-planejamento",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/visualizar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "planejaConfig",
            path: "visu-especifico-planejamento-pedagogico",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/planejamento_config.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "planejamentoPedaCad",
            path: "cadastro-planejamento-pedagogico",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/cadastrar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "planejamentoPedaEdit",
            path: "editar-planejamento-pedagogico",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/planejamento_pedagogico/gestor/editar.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "visualizarturma",
            path: "visualizar-turma",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/visualizar_turma.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "turma-add-Professor",
            path: "turma-add-professor",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/turmas/gestor/turma_add_professor.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "turmasDisciplina",
            path: "turmas-adicionar-Disciplina",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/turmas/gestor/turma_add_disciplina.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "turma-add-aluno",
            path: "turma-adicionar-aluno",
            props: true,
            component: () =>
              import("@/pages/admin/pages/turmas/gestor/turma_add_aluno.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 9) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "presencaAll",
            path: "presenca-por-turma",
            props: true,
            component: () =>
              import("@/pages/admin/pages/presenca/gestor/presenca-turmas.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 10) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
          {
            name: "presencasDoAluno",
            path: "presenca-do-aluno",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/gestor/presenca-do-aluno.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 10) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "presencaConfigAll",
            path: "presenca-por-turma-config",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/presenca/gestor/presenca-turmas_config.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 10) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "notasAll",
            path: "notas-por-turma",
            props: true,
            component: () =>
              import("@/pages/admin/pages/notas/gestor/notas-turmas.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 12) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "notasAllTurma",
            path: "notas-da-turma",
            props: true,
            component: () =>
              import("@/pages/admin/pages/notas/gestor/notas-turma_config.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 12) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: 'relatorios-gestor',
            path: 'relatorios-gestor',
            props:true,
            component: () => import('@/layout/relatorio-layout.vue'),
            beforeEnter: async (to, from, next) => {
              next('/relatorios/dashboard');
            }
          },

          {
            name: "conteudo-aplicado-gestor",
            path: "conteudo-aplicado-gestor",
            props: true,
            component: () =>
              import("@/pages/admin/pages/conteudo-aplicado/gestor/index.vue"),
            beforeEnter: async (to, from, next) => {
              next("/relatorios/dashboard");
            },
          },

          {
            name: "conteudo-aplicado-gestor",
            path: "conteudo-aplicado-gestor",
            props: true,
            component: () =>
              import("@/pages/admin/pages/conteudo-aplicado/gestor/index.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 12) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "arquivos_xml",
            path: "arquivos_xml",
            props: true,
            component: () =>
              import("@/pages/admin/pages/arquivos_xml_envio/arquivo_xml.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 0;

                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 12) {
                      permissao = 1;
                    }
                  }
                } else {
                  permissao = 1;
                }

                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "escolaridade",
            path: "escolaridade",
            props: true,
            component: () =>
              import("@/pages/admin/pages/escolaridade/escolaridade.vue"),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);

                if (result) {
                  return next();
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },

          {
            name: "gestor/transferencia",
            path: "/gestor/transferencia",
            props: true,
            component: () =>
              import(
                "@/pages/admin/pages/transferencia/gestor/transferencia.vue"
              ),
            beforeEnter: async (to, from, next) => {
              try {
                const verificar = await verificarToken();
                const result = await ambienteADM(verificar);
                let permissao = 1;
                if (verificar.funcao != 1) {
                  const permissoes = verificar.permissao;
                  for (const el of permissoes) {
                    if (el.permissao_modulo_id == 18) {
                      permissao = 1;
                    } else {
                      permissao = 0;
                    }
                  }
                }
                if (result) {
                  if (permissao) {
                    return next();
                  } else {
                    next("/auth/login");
                    return next(false);
                  }
                } else {
                  next("/auth/login");
                  return next(false);
                }
              } catch (e) {
                next("/auth/login");
              }
            },
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("@/pages/auth/login/Login.vue"),
      },
      {
        name: "2af",
        path: "2af",
        props: true,
        component: () => import("@/pages/auth/2af/verify.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    path: "/404",
    component: Page404Layout,
    children: [
      {
        name: "not-found-advanced",
        path: "not-found-advanced",
        component: () => import("@/pages/404-pages/VaPageNotFoundSearch.vue"),
      },
      {
        name: "not-found-simple",
        path: "not-found-simple",
        component: () => import("@/pages/404-pages/VaPageNotFoundSimple.vue"),
      },
      {
        name: "not-found-custom",
        path: "not-found-custom",
        component: () => import("@/pages/404-pages/VaPageNotFoundCustom.vue"),
      },
      {
        name: "not-found-large-text",
        path: "/pages/not-found-large-text",
        component: () =>
          import("@/pages/404-pages/VaPageNotFoundLargeText.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes,
});

export default router;
