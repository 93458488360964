<template>
  <div class="app-navbar-actions">
    <!--<a href="mailto:hello@epicmax.co"
      target="_blank"
    >
      hello@epicmax.co
    </a> -->
    
    <!--<router-link to="home" class="app-navbar-actions__item itens-menu-superior" > Home </router-link>
    <router-link to="esic" class=" app-navbar-actions__item itens-menu-superior"> e-SIC </router-link>
    <router-link to="ouvidoria"  class=" app-navbar-actions__item itens-menu-superior"> Ouvidoria </router-link>

    <ajuda-dropdown class="app-navbar-actions__item app-navbar-actions__item--profile ">
      <span>Ajuda</span>
    </ajuda-dropdown>

    <router-link to="fale-Conosco" class="app-navbar-actions__item itens-menu-superior"> Fale Conosco </router-link>

    <color-dropdown class="app-navbar-actions__item"/>

    <a href="https://www.gov.br/governodigital/pt-br/vlibras" class="app-navbar-actions__item itens-menu-superior" target="_blank">
      <i class="fa fa-universal-access va-icon fa fa-universal-access" aria-hidden="true" notranslate="" style="font-size: 30px;"></i>
    </a>  -->

    <!--<message-dropdown class="app-navbar-actions__item"/>-->
    <!--<notification-dropdown class="app-navbar-actions__item"/>-->
    <!-- <settings-dropdown class="app-navbar-actions__item" /> -->
    <!--<language-dropdown class="app-navbar-actions__item"/>-->
    <profile-dropdown :funcaoDoUsuario="funcaoDoUsuario" class="app-navbar-actions__item app-navbar-actions__item--profile">
      <span>{{userName}}</span>
    </profile-dropdown>
  </div>
</template>

<script>
import LanguageDropdown from './dropdowns/LanguageDropdown'
import ProfileDropdown from './dropdowns/ProfileDropdown'
import NotificationDropdown from './dropdowns/NotificationDropdown'
import MessageDropdown from './dropdowns/MessageDropdown'
import ColorDropdown from './dropdowns/color-dropdown/ColorDropdown'
//import SettingsDropdown from './dropdowns/SettingsDropdown'
import AjudaDropdown from './dropdowns/AjudaDropdown'

export default {
  name: 'app-navbar-actions',

  components: {
    // SettingsDropdown,
    ColorDropdown,
    /*MessageDropdown,
    NotificationDropdown,*/
    //LanguageDropdown,
    ProfileDropdown,
    AjudaDropdown
  },

  props: {
    userName: {
      type: String,
      default: '',
    },
    isTopBar: {
      type: Boolean,
      default: false,
    },
    funcaoDoUsuario:{}
  },

  computed: {
    isTopBarProxy: {
      get () {
        return this.isTopBar
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar)
      },
    },
  },
}
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }
  
  &__item {
    padding: 0;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    
    svg {
      height: 24px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &--profile {
      display: flex;
      justify-content: center;
      margin: auto 0 auto 1.25rem;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }

      &--profile {
        position: absolute;
        right: 0.75rem;
        top: 1.25rem;
        height: fit-content;
        margin: auto;
      }
    }
  }
}
</style>

<style>
  .itens-menu-superior{

    padding-left:5px;
    padding-right:5px;
  }
</style>