<template>
  <div class="app-layout">
    <navbar />
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
        <div v-if="isFullScreenSidebar" class="d-flex justify--end">
          <va-button
            class="px-4 py-4"
            icon="close"
            flat
            color="dark"
            @click="onCloseSidebarButtonClick"
          />
        </div>
        <sidebar
          :width="sidebarWidth"
          :minimized="isSidebarMinimized"
          :minimizedWidth="sidebarMinimizedWidth"
        />
      </div>
      <div class="app-layout__page">
        <div class="layout fluid gutter--xl">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import Sidebar from '@/components/sidebar/Sidebar';
import Navbar from '@/components/navbar/Navbar.vue';

export default {
  name: 'app-layout',

  components: {
    Navbar, Sidebar
  },

  setup() {
    const store = useStore()
    const mobileBreakPointPX = 640
    const tabletBreakPointPX = 768

    const sidebarWidth = ref('16rem')
    const sidebarMinimizedWidth = ref(undefined)

    const isMobile = ref(false)
    const isTablet = ref(false)
    const isSidebarMinimized = computed(() => store.state.isSidebarMinimized)
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX

    const onResize = () => {
      store.commit('updateSidebarCollapsedState', checkIsTablet())

      isMobile.value = checkIsMobile()
      isTablet.value = checkIsTablet()
      sidebarMinimizedWidth.value = isMobile.value ? 0 : '4rem'
      sidebarWidth.value = isTablet.value ? '100%' : '16rem'
    }

    onMounted(() => {
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onBeforeRouteUpdate(() => {
      if (checkIsTablet()) {
        // Collapse sidebar after route change for Mobile
        store.commit('updateSidebarCollapsedState', true)
      }
    })

    onResize()

    const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

    const onCloseSidebarButtonClick = () => {
      store.commit('updateSidebarCollapsedState', true)
    }

    return {
      isSidebarMinimized,
      sidebarWidth, sidebarMinimizedWidth,
      isFullScreenSidebar, onCloseSidebarButtonClick
    }
  }
}
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
   // height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;
  }
}
</style>

<style>
 a{
   text-decoration: none !important;
 }

 .titulo-links-escolha{
    margin-top: 25px;
    margin-bottom: 25px;
    color: #212121;
    font-size: 31px;
    font-weight: 600;
 }
 .links-escolha{
    text-align: center;
    padding: 20px 6px;
    border-radius: 4px;
    background-color: #f7f8fc;
    margin-bottom: 30px;
 }
 @media(min-width: 1400px){
   .links-escolha{
      height: 260px;
   }
 }

 .links-escolha:hover{
    background-color: rgba(2, 117, 97, 0.07);
    -webkit-transform: perspective(0px);
    transform: perspective(0px);
  }

 .links-escolha a,.links-escolha a:hover{
    color: #686868;
    text-decoration: none;
 }
 .links-escolha a h3{
    text-align:center;
    font-weight:700 !important;
    font-size: 14px !important;
    letter-spacing: 1px;
 }
 .links-escolha a p{
    text-align:center;
    font-size: 12px !important;
 }

 .form-control {
    -webkit-appearance: auto !important;
    -moz-appearance: auto !important;
    appearance: auto !important;
  }

  .card {
    /*box-shadow: 0 4px 70px -18px #7070704f !important;*/
    box-shadow: 0 2px 7px -18px hsla(0,0%,43.9%,.30980392156862746)!important
  }

  .card-padd{
    padding: 2rem;
    padding-top: 10px;
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  .card-steps{
    padding: 2rem;
    border-radius: 10px;
    /*margin-bottom: 2rem;*/
  }

  .app-layout__page{
    background-color: #eff3f8 !important;
  }

  /*.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 1rem !important;
  }*/
  .btn-color{
    font-weight: 700 !important;
    color: #fff !important;
  }

  .pm-card{
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  /*.tb-center{
    width:100%;
    height: auto;
    text-align:center;
  }*/

  .img-perfil-dtb-pq{
    width: 35px;
    height: 35px;
    border-radius: 20px;
  }

  .tb-center:hover + .img-perfil-dtb{
    transition: transform 300ms cubic-bezier(0.2, 0, 0.2, 1);
    display:block !important;
  }

  .img-perfil-dtb{
    width: 100px;
    display:none;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    margin-top: -36px;

  }

  .va-card{
    box-shadow: 0 0 !important;
    border: 1px solid #e7e7e7;
  }

  .alig-pesq{
    padding-top: 20px;
  }

  @media(min-width:1920px){
    .alig-pesq{
      padding-top: 24px;
    }
  }
  .titulo-caixa{
    margin-bottom: 25px;
    color: #212121;
    font-size: 31px;
    font-weight: 600;
  }

  @media(max-width:992px){
    .titulo-caixa{
      font-size: 22px;
    }
  }

  #msg_comunic .p-datatable-wrapper table thead{
    display:none !important;
  }

  #servidores .p-datatable-wrapper table thead tr th:nth-child(1) div .p-sortable-column-icon {
    display:none;
  }

  #servidores .p-datatable-wrapper table thead tr th:nth-child(4) div .p-sortable-column-icon {
    display:none;
  }
  #servidores .p-datatable-wrapper table thead tr th:nth-child(5) div .p-sortable-column-icon {
    display:none;
  }
  #servidores .p-datatable-wrapper table thead tr th:nth-child(6) div .p-sortable-column-icon {
    display:none;
  }
  #servidores .p-datatable-wrapper table thead tr th:nth-child(7) div .p-sortable-column-icon {
    display:none;
  }
  .bdge1{
    background-color: #0d72f0;
  }

  .small-switch{
    width: 2.5rem !important;
    height: 1.25rem !important;
  }

  .small-switch .p-inputswitch-slider:before{
    width: 1rem !important;
    height: 1rem !important;
    top: 11px;
    left: 0.1rem !important;
  }

  .table_style{
    font-weight: normal!important;
  }

  .table_style .va-badge__text {
    font-weight: normal!important;
  }


  .field {
    font-size: 13px !important;
  }

  .p-inputtext {
    font-size: 13px !important;
  }
  .p-dropdown-item {
    font-size: 13px !important;
  }

  .btn-semsombra{
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 0%), 0 2px 2px 0 rgb(0 0 0 / 0%), 0 1px 5px 0 rgb(0 0 0 / 0%) !important;
  }

  .p-datatable-wrapper{
    text-transform: uppercase;
  }

  /*aluno,servidor*/

  @media(min-width:1367px){
   .cep_responsive{
      width: 7% !important;
   }
   .num_responsive{
      width: 5% !important;
   }
   .estado_responsive{
     width: 4% !important;
   }
  }

  .va-badge__text-wrapper{
    border-radius:3px !important;
  }

  .va-dropdown__content{
    padding: 0px !important;
    padding-top:2px !important;
    padding-bottom:2px !important;
  }

  .va-dropdown .va-dropdown__anchor .va-button {
    background: #3B82F6 !important;
    border: 1px solid #3B82F6 !important;
    border-radius: 4px !important;
  }

  .n_ger .va-list{
    padding: 0px!important;
  }

  .n_ger .va-list .va-list-item:hover ,.va-list-item-label:hover{
    color:#1D4ED8 !important;
    background: #EFF6FF !important;
  }

  .n_ger .va-list .va-list-item .va-list-item__inner {
    padding-right: 30px !important;
    padding-top: 1px!important;
    padding-bottom: 1px!important;
    padding-left: 0px!important;
    font-size: 11px !important;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link{
    background: #ffffff !important;
  }

  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #ffffff !important;
  }


</style>
