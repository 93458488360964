<template>
  <div class="barr-footer">
    <div class="container-fluid barr-footer-box-sha" :style="'background-color: '+backgroundcolor+'; '">
      <div class="row" style="margin: 15px;margin-bottom: 0px;">
        <div v-for="n in menuItems" :key="n" :class="'barr-footer-button col-'+qdt" >
          <router-link :to="n.url" class="barr-footer-item" >
            <i :class="n.icon+' barr-footer-icon'" :style="'color:'+textcolor"></i>
            <label v-if="n.text" :style="'margin: 0px!important;width:100%;color:'+textcolor">{{ n.text }}</label>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.barr-footer{
  margin-top: -60px;z-index: 1;display:none;
  position: fixed;
  bottom: 2px;
  width: 100%;
}
@media(max-width:992px){
 .barr-footer{
    display: block;
 }
}
.barr-footer-box-sha{
    -webkit-box-shadow: 17px 0px 3px 1px rgb(221 221 221);
    -moz-box-shadow: 17px 0px 3px 1px rgb(221 221 221);
    box-shadow: 17px 0px 3px 1px rgb(221 221 221);
}
.barr-footer-button{
    padding: 10px;
    padding-top:15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .barr-footer-item{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .barr-footer-icon{
    font-size: 32px;
  }
</style>

<script>
export default {
  props: {
    textcolor: {},
    backgroundcolor: {},
    menuItems:{},
  },
  data() {
    return {
      qdt: 0,
    }
  },
  beforeMount(){
    console.log(this.menuItems.lenght)
    if(this.menuItems.length < 4){
      this.qdt = 4;
    }else if(this.menuItems.length > 3 && this.menuItems.length < 7){
      this.qdt = 3;
    }else{
      this.qdt = 2;  
    }
  }
}
</script>