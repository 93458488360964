export default {
  root: {
    name: '/',
    displayName: 'navigationRoutesProfessor.home',
  },
  routes: [
    /*{
      name: 'turmas',
      displayName: 'Turmas',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },*/
    {
      name: 'ambiente-professor',
      displayName: 'Area de Trabalho',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'calendarioProfessor',
          displayName: 'Calendario Letivo',
        },
        {
          name: 'turmas-Professor',
          displayName: 'Turmas do Professor',
        },
        {
          name: 'presencaProfessorEditar',
          displayName: 'Visualizar Presenças',
        },
        // {
        //   name: 'relatorios-professor',
        //   displayName: 'Relatórios',
        // },
      ]
    }
  ],
}
