import axios from 'axios';
import store_token_info from "@/store/store_token_info.js";

class Util {
  async WhoIam (token){
    try {
      const ano = sessionStorage.getItem("anoSelecionado");
      const res = await axios.get('/whoiam/ano/'+ano,{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      });
      //console.log(res);

      store_token_info.usuario_logado = res.data.id;
      return res.data;
    } catch (e) {
      return e;
    }
  }
}

export default Util;
