<template>
  <div id="noprint">
    <router-view/>
  </div>
  <div id="printme"></div>
</template>


<style lang="scss">
@import '~@/sass/main.scss';
#app {
  font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  background: var(--va-background);
}

.va-data-table__table-th{
    background-color: rgb(0, 103, 167) !important;
    color: rgb(255, 255, 255) !important;
    padding: 15px !important;
    font-size: 15px !important;
    border: 2px solid #fff !important;
}

</style>
