import { VuesticPlugin, useGlobalConfig } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'

import { createRouter } from "vue-router";
//import * as Sentry from "@sentry/vue";

//import vSelect from "vue-select-3";


const i18nConfig = {
  locale: 'br',
  fallbackLocale: 'en',
  messages: {
    en: require('@/i18n/en.json'),
    ch: require('@/i18n/cn.json'),
    es: require('@/i18n/es.json'),
    ir: require('@/i18n/ir.json'),
    br: require('@/i18n/br.json')
  }
}

import 'jquery'
import 'bootstrap'

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

//modal
import 'gitart-vue-dialog/dist/style.css'

import {axiosConfig} from './utils/axios'
axiosConfig()


const app = createApp(App)
app.use(BootstrapVue3)
app.use(store)
app.use(router)
if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  app.use(createGtm(gtmConfig))
}

/*
Sentry.init({
  app,
  dsn: "https://a3019c3d37c343c8990e549f209828bf@o4504973472169984.ingest.sentry.io/4504996691312640",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "sge.cardosomoreira.rj.gov.br", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

import PrimeVue from 'primevue/config';
import '/node_modules/primeflex/primeflex.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.css'
import 'primeicons/primeicons.css'
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Breadcrumb from 'primevue/breadcrumb';
import InputSwitch from 'primevue/inputswitch';
import CascadeSelect from 'primevue/cascadeselect';
import Steps from 'primevue/steps';
import Toast from 'primevue/toast';
import SpeedDial from 'primevue/speeddial';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import FileUpload from 'primevue/fileupload';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import TabMenu from 'primevue/tabmenu';
import Timeline from 'primevue/timeline';
import Checkbox from 'primevue/checkbox';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import PickList from 'primevue/picklist';
import SplitButton from 'primevue/splitbutton';
import AutoComplete from 'primevue/autocomplete';
import ColorPicker from 'primevue/colorpicker';
import Panel from 'primevue/panel';
import Tooltip from 'primevue/tooltip';
import OrderList from 'primevue/orderlist';
import Editor from 'primevue/editor';

app.use(PrimeVue, {
  locale: {
       closeText : 'Fechar',
      prevText : 'Anterior',
      nextText : 'Próximo',
      currentText : 'Começo',
      monthNames : [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho','Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ],
      monthNamesShort : [ 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago','Set', 'Out', 'Nov', 'Dez' ],
      dayNames : [ 'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta','Sábado' ],
      dayNamesShort : [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb' ],
      dayNamesMin : [ 'Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa' ],
      weekHeader : 'Semana',
      firstDay : 1,
      isRTL : false,
      showMonthAfterYear : false,
      yearSuffix : '',
      timeOnlyTitle : 'Só Horas',
      timeText : 'Tempo',
      hourText : 'Hora',
      minuteText : 'Minuto',
      secondText : 'Segundo',
      ampm : false,
      month : 'Mês',
      week : 'Semana',
      day : 'Dia',
      allDayText : 'Todo Dia'
  }

});

app.component('pm-Button', Button);
app.component('pm-SplitButton', SplitButton);
app.component('pm-DataTable', DataTable);
app.component('pm-Column', Column);
app.component('pm-ColumnGroup', ColumnGroup);
app.component('pm-Row', Row);
app.component('pm-InputText', InputText);
app.component('pm-Card', Card);
app.component('pm-Breadcrumb', Breadcrumb);
app.component('pm-InputSwitch', InputSwitch);
app.component('pm-CascadeSelect', CascadeSelect);
app.component('pm-Steps', Steps);
app.component('pm-Toast', Toast);
app.component('pm-SpeedDial', SpeedDial);
app.component('pm-Toolbar', Toolbar);
app.component('pm-TabView', TabView);
app.component('pm-TabPanel', TabPanel);
app.component('pm-InputNumber', InputNumber);
app.component('pm-Password', Password);
app.component('pm-RadioButton', RadioButton);
app.component('pm-InputMask', InputMask);
app.component('pm-Calendar', Calendar);
app.component('pm-Dropdown', Dropdown);
app.component('pm-Dialog', Dialog);
app.component('pm-ConfirmDialog ', ConfirmDialog);
app.component('pm-Accordion', Accordion);
app.component('pm-AccordionTab', AccordionTab);
app.component('pm-FileUpload', FileUpload);
app.component('pm-MultiSelect', MultiSelect);
app.component('pm-Textarea', Textarea);
app.component('pm-Avatar', Avatar);
app.component('pm-Menu', Menu);
app.component('pm-TabMenu', TabMenu);
app.component('pm-Timeline', Timeline);
app.component('pm-Checkbox', Checkbox);
app.component('pm-Skeleton', Skeleton);
app.component('pm-ProgressSpinner', ProgressSpinner);
app.component('pm-PickList', PickList);
app.component('pm-AutoComplete', AutoComplete);
app.component('pm-ColorPicker', ColorPicker);
app.component('pm-Panel', Panel);
app.component('pm-OrderList', OrderList);
app.component('pm-Editor', Editor);


//app.component("v-select", vSelect);

import { VMaskDirective } from 'v-slim-mask'

app.directive('mask', VMaskDirective);
app.directive('tooltip', Tooltip);

import ConfirmationService from "primevue/confirmationservice";

//const VueMask = require('vue-jquery-mask');
//app.use(VueMask);
import ToastService from 'primevue/toastservice';

app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)
app.use(ConfirmationService)
app.use(ToastService)
app.mount('#app')

