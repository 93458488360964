import axios from 'axios'

export class LoginInfo {
    static async Logar (info, senha) {
        try {
            sessionStorage.removeItem("token");
            let res =  await axios.post('/auth', {
                login: info,
                password: senha,
            });
            sessionStorage.setItem("token", res.data.token);
            return 1;
        }
        catch(e) {
            return 0;
        }
    }
    static async meuLogin () {
        return axios.post('/auth/me')
    }
    static async logout () {
        return axios.get('/auth/logout')
    }

    static async WhoIam(token, ano) {
      try {
        if (ano == undefined) {
          ano = sessionStorage.getItem("anoSelecionado");
        }
        return axios.get('/whoiam/ano/'+ano, {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          //cache
          headers: {
            'cache-control': 'max-age=3600',
          }
        });

      } catch (e) {
        return e;
      }
    }

    static async trocarSenhaPerfil (playload){
        try {
          return axios.post('/trocasenha',playload);

        } catch (e) {
          return e;
        }
    }

    static async enviarEmailEsqueceuSenha (playload){
      try {
        return axios.post('/esqueceu-senha',playload);

      } catch (e) {
        return e;
      }
    }

    static async trocarSenhaNoEsqueceuSenha (playload){
      try {
        return axios.post('/nova-senha',playload);
      } catch (e) {
        return e;
      }
    }
}
